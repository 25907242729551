/*-----------------------------------------------------------------------------------

  Author: ENREDA
  Author URI: https://enreda.coop
  Version: 0.0.1

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------

  Table Of Content
    
    00 Basics
      00.01 Backgrounds
      00.02 Fonts
      00.03 Paddings
      00.04 Margins
    01 Navbar
    02 Footer
    03 Links
    04 Buttons
    05 Images
    06 Icons
    07 Carousel
    08 Blocks
    09 Cards
    10 Desktop View
    11 Room View
    12 Section Download

 
---------------------------------------------------------------- */
/* ----------------------------------------------------------------
  [ 00 Basics ]
-----------------------------------------------------------------*/
:root {
  --default:      #000000;
  --white:        #FFFFFF;

  --primary:      #579BA8;
  --primary-dark: #386973;
  --clear:        #EFEFEF;;
  --light:        #E2E2E2;
  --gray:         #6c757d;
  --dark:         #404040;
  --shadow-color: rgba(0,0,0,.13);
}
*{
  line-height: normal;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.min-height-100 {
  min-height: 100vh;
}
.min-height-2rem {
  min-height: 2rem;
}

.hidden {
  display: none;
}
.show {
  display: initial;
}

hr.line-light {
  background: var(--shadow-color);
  margin: .4rem 0;
}

/* ----------------------------------------------------------------
  [ 00.01 Backgrounds ]
-----------------------------------------------------------------*/
.bg-primary {
  background-color: var(--primary) !important;
}
.bg-light {
  background-color: var(--light) !important;
}
.bg-clear {
  background-color: var(--clear);
}
.bg-white {
  background-color: var(--white) !important;
}


/* ----------------------------------------------------------------
  [ 00.02 Fonts ]
-----------------------------------------------------------------*/
.text-custom{
  text-shadow: 0px 7px 20px var(--default), 0px -7px 20px var(--default), -7px 0px 20px var(--default), 8px 0px 20px var(--default), 8px 0px 20px var(--default);
}
.room-title {
  text-align: center;
  font-size: 2rem;
  /*width: 70%;*/
}
.room-year {
  text-align: center;
  font-size: 23px;
  position: relative;
}
.text-gray {
  color: var(--gray);
}
.text-small {
  font-size: 15px !important;
}

/* ----------------------------------------------------------------
  [ 00.03 Paddings ]
-----------------------------------------------------------------*/
.pb-60 {
  padding-bottom: 60px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-150 {
  padding-bottom: 150px;
}

.pt-60 {
  padding-top: 30px;
}
/*.pt-5 {
  padding-top: 80px !important;
}*/

.pt-room-header {
  padding-top: 50px;
}
@media only screen and (max-width: 397px) {
  .pt-room-header {
    padding-top: 0px;
  }  
}

/* ----------------------------------------------------------------
  [ 00.04 Margins ]
-----------------------------------------------------------------*/
.m-0 {
  margin: 0;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-120 {
  margin-bottom: 120px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-80 {
  margin-top: 80px;
}



.info-p > p{
  padding: 0;
  margin: 0;
}
.info-p{
  position: relative;
}


.view-enter {
  opacity: 0;
  transform: translateY(5rem);
}

.view-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 450ms, transform 350ms;
}

.view-exit {
  opacity: 1;
  transform: translateY(0);
}

.view-exit-active {
  opacity: 0;
  transform: translateY(4rem);
  transition: opacity 450ms, transform 350ms;
}




/* ----------------------------------------------------------------
  [ 01 Navbar ]
-----------------------------------------------------------------*/
.custom-navbar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: inline-block;
  padding: .8rem;
  z-index: 1030;
  box-shadow: var(--shadow-color) 0px 2px 13px;
  background-color: #FFF;
}
  .brand-title {
    font-size: 2rem;
    line-height: normal;
    color: var(--first-color);
  }

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 45px;
  box-shadow: 0px 3px 6px var(--gray);
  background-color: #FFF;
}
.sidenav-opn {
  width: 300px;
}
.open-btn{
  position: fixed;
  top: 3px;
  left:5px;
  color: var(--default);
  /*background-color: #101010;*/
  cursor:pointer;
  font-size: 30px;
  margin-left: 6px;
  z-index: 1500;
}
.sidenav a, .sidenav .btn-sidebar {
  padding: 4px 8px 4px 32px;
  text-decoration: none;
  font-size: 25px;
  display: block;
  transition: 0.3s;
}
.sidenav a:hover {
  color: var(--dark);
}
.sidenav .closebtn {
  position: absolute;
  top: -5px;
  right: 25px;
  font-size: 40px;
  margin-left: 50px;
  cursor:pointer;
  z-index: 1051;
}
.sidenav a, .sidenav button{
  color:var(--first-color);
}
.sidenav-selected{
  background-color: var(--first-color);
  color: #FFF !important;
}
.navbar-footer{
  transform: translateY(0);
  transition: transform 0.25s;
}
.navbar-footer-hidden{
  transform: translateY(100%);
}
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

.navbar-bottom-fixed {
  box-shadow: 0 -2px 13px var(--shadow-color);
}
  

.my-dropdown-toggle::after {
  display:block;
  border: 0px;
  content: "";
  top: 100%;
  left: 0;
  height: 20px;
  width: 20px;
  background: url(images/vertical-dots.svg) bottom center;
  background-size: cover;
  vertical-align: middle;
}

/* ----------------------------------------------------------------
  [ 02 Footer ]
-----------------------------------------------------------------*/


/* ----------------------------------------------------------------
  [ 03 Links ]
-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
  [ 04 Buttons ]
-----------------------------------------------------------------*/
.btn-large {
  font-size: 18px;
}

.btn,
.btn-primary:focus,
.btn-light:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
  border: none;
  outline: none;
}
.btn-primary {
  background: var(--primary) !important;
  color: var(--white) !important;
}
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    background: var(--primary-dark) !important;
  }
.btn-light {
  background: var(--light) !important;
  color: var(--gray) !important;
}
  .btn-light:hover, .btn-light:focus, .btn-light:focus {
    color: var(--dark) !important;
  }
.btn-gray {
  background: var(--gray) !important;
  color: var(--light) !important;
}
  .btn-gray:hover, .btn-gray:focus, .btn-gray:focus {
    background: var(--primary) !important;
    color: var(--light) !important;
  }
.btn-dark {
  background: var(--gray) !important;
  color: var(--light) !important;
}
  .btn-dark:hover, .btn-dark:focus, .btn-dark:focus {
    background: var(--dark) !important;
    color: var(--light) !important;
  }

.btn-img-hover:hover img,
.btn-img-hover:active img, 
.btn-img-hover:focus img {
  filter: brightness(100);
  outline: none;
}
/* ----------------------------------------------------------------
  [ 05 Images ]
-----------------------------------------------------------------*/
.img-circle {
  clip-path: circle(at 50% 50%);
  width: 100%;
  align-self: center;
}
.room-img {
  width: 10rem;
  /*height: 200px;*/
  object-fit: contain;
  margin: 0 auto;
}
.img-profile {
  width: 5rem;
}
.img-message {
  width: 5rem;
  /*height: 5rem;*/
}
.img-login {
  width: 10rem;
}
/* ----------------------------------------------------------------
  [ 06 Icons ]
-----------------------------------------------------------------*/
.icon-reload {
  float:right;
  mask: url(images/redo-arrow-symbol.svg);
}
.icon {
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--first-color);
}
.icon-menu {
  margin-top:0.6rem;
  mask: url(images/parallel-bars.svg);
}
.icon-close {
  margin-top:1.25rem;
  mask: url(images/delete.svg);
}
.icon-check {
  margin-top:1.25rem;
  mask: url(images/check.svg);
}
.icon-whatsapp {
  width: 20px;
}
  a:hover .icon-whatsapp {
    filter: brightness(1);
  }
.icon-edit {
  width: 1.2rem;
  vertical-align: middle;
  cursor: pointer;
}
  .icon-edit:hover {
    filter: contrast(2) brightness(2);
  }

/* ----------------------------------------------------------------
  [ 07 Carousel ]
-----------------------------------------------------------------*/
.carousel-caption{
  position: static !important;
  padding: 0 !important;
  font-size: 1.35rem;
}
#carousel2 .carousel-item, #carousel3 .carousel-item{
   transition: transform 3s ease, opacity .5s ease-out
}

  .block-carousel .carousel-inner {
    border-radius: 3px;
    padding: 10px;
  }
  .block-carousel .carousel-inner .carousel-caption a {
    color: var(--gray);
  }
  .block-carousel .carousel-control-prev,
  .block-carousel .carousel-control-next {
    cursor: pointer;
  }
  .block-carousel .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%236c757d' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
  }
  .block-carousel .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%236c757d' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
  }

/* Efecto para cambio de elementos carousel */
.carousel.carousel-fade .item {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}
  .carousel.carousel-fade .active.item {
    opacity:1;
  }
  


/* ----------------------------------------------------------------
  [ 08 Blocks ]
-----------------------------------------------------------------*/
.block-candle {
  /*position: absolute;*/
  top: 125px;
  right: 150px;
}

  figure.fig-candle {
    width: 5.5rem;
    height: 90px;
    margin: 0 auto;
  }
  .block-candle figure figcaption {
    font-size: 0.7rem;
    margin-top: 5px;
    line-height: normal;
  }
  figure.fig-candle figcaption,
  figure.fig-candle figcaption small {
    font-size: 1rem;
    margin-top: 5px;
  }
    .block-candle figure figcaption a,
    figure.fig-candle figcaption a {
      color: var(--gray);
    }
  .block-candle figure img,
  figure.fig-candle img {
    background: #E2E2E2;
    /* padding: 15px; */
    border-radius: 100%;
  }

.block-candle.without-image {

}

@media only screen and (max-width: 767px) {
  .block-candle {
    top: 70px;
    right: 17px;
  }
}
@media only screen and (max-width: 991px) {
  .block-candle.without-image {
    position: inherit;
    width: 100%;
    text-align: center;
    padding: 20px 0px;
  }
    .block-candle.without-image figure {
      width: 75px;
      height: 75px;
      margin: 0 auto;
    }
}

.block-message {
  padding: 15px;
  border-radius: 3px;
  text-align: center;
  font-size: 17px;
}
  .block-message p:first-child {
    margin-top: 10px;
  }
  .block-message p:last-child {
    margin-bottom: 10px;
  }

.block-light, .block-primary, .block-gray {
  padding: 15px;
  border-radius: 13px;
  height: 100%;
}
  .block-primary {
    background: var(--primary);
    color: var(--light);
  }
  .block-light {
    background: var(--light);
  }
  .block-gray {
    /*background: var(--gray);*/
    /*color: var(--light);*/
  }

.block-buttons {
  padding: 0 !important;
}
.block-buttons > a, .block-buttons > button {
  width: 50%;
  box-sizing: border-box;
  margin: 0px !important;
  border-radius: 27px;
  vertical-align: middle;
  line-height: normal;
}

.btn-et{
  border-radius: 27px;
  line-height: normal;
}
.min-h-3{
  min-height: 3rem;
}

.block-list {
  background: #FFF;
  border-bottom: 1px solid var(--shadow-color);
  padding-bottom: 15px;
  padding-top: 15px;
  color: var(--gray);
  min-height: 8rem;
}
  .block-list > h4 {
    color: var(--first-color);
  }

/* ----------------------------------------------------------------
  [ 09 Cards ]
-----------------------------------------------------------------*/
.card .card-title,
.card .card-body {
  color: var(--gray);
}
.card .card-body {
  font-size: 17px;
}
.card .card-title span {
  margin-left: 15px;
  font-size: 17px;
}

/* ----------------------------------------------------------------
  [ 10 Desktop View ]
-----------------------------------------------------------------*/
.desktop-view {
  max-width: 100%;
}
.desktop-view .custom-navbar {
  height: 60px;
}
  .desktop-view .custom-navbar .brand-title {
    font-size: 30px;
  }
  .desktop-view .custom-navbar .brand-title > img {
    height: 30px;
  }
  .desktop-view .custom-navbar .title-desktop {
    font-weight: normal;
    color: var(--shadow-color);
  }
  .desktop-view .custom-navbar > a {
    float: right;
  }
.desktop-view .room-img {
  max-width: 350px;
}
.desktop-view .room-year {
  font-size: 30px;
}
.desktop-view .block-desktop-info > h3 {
  display: block;
  font-size: 27px;
  text-align: left;
  padding: 20px 10px;
  border-bottom: 1px solid var(--shadow-color);
}
  .desktop-view .block-desktop-info > h3 span {
    display: block;
    font-weight: bold;
    font-size: 2.1rem;
    word-break: break-word;
  }
  .desktop-view .block-desktop-info > h3 img {
    width: 50px;
    margin-right: 15px;
    top: -10px;
    position: relative;
  }
  .desktop-view .block-desktop-info > h3:last-child {
    border-bottom: none;
  }
.desktop-view .card .card-title {
  font-size: 27px;
}
.desktop-view .card .card-body {
  font-size: 20px;
}
.desktop-view .fig-candle {
  height: initial;
}
.desktop-view .fig-candle figcaption,
.desktop-view .fig-candle figcaption small {
  font-size: 25px !important;
  line-height: 27px;
}
.desktop-view .block-messages .card {
  width: 50%;
}
.desktop-view .block-candles {
  /*background: var(--light);*/
  border-radius: 13px;
  margin: 15px;
  padding: 20px;
}
.desktop-view .wrapper-block-messages {
  border-radius: 13px;
  padding: 20px;
  margin-top: 20px;
}
.desktop-view .wrapper-block-messages .carousel-item {
  padding-left: 30px;
  padding-right: 30px;
}
/* ----------------------------------------------------------------
  [ 11 Room View ]
-----------------------------------------------------------------*/
.section-room {
    padding-top: 80px !important;
  }
@media only screen and (max-width: 398px) {
  .section-room {
    padding-top: 150px !important;
  }
}
/* ----------------------------------------------------------------
  [ 12 Section Download ]
-----------------------------------------------------------------*/
.section-download {
  margin-top: 80px;
  background: var(--light);
  padding: 30px;
  border-radius: 6px;
}
/* ----------------------------------------------------------------
  modal login un phoneRoom
-----------------------------------------------------------------*/
.login-modal-show{
  top: 0px !important;
  left:0px !important;
  opacity: 1 !important;
}
.login-modal{
  opacity: 0;
  z-index: 5000;
  top: 200vh;
  left:0px;
  -webkit-transition: top 0.5s, opacity 0.4s; /* Safari prior 6.1 */
  transition: top 0.5s, opacity 0.4s;
}
/* ----------------------------------------------------------------
  flower scroll select
-----------------------------------------------------------------*/
.flower-checkbox + label {
  border: 2px solid white;
  -webkit-transition: border 0.5s, transform 0.25s; /* Safari prior 6.1 */
  transition: border 0.5s, transform 0.25s;
} 
.flower-checkbox:checked + label {
  border: 2px solid var(--primary);
  transform: scale(1.3);
} 
.x-scroll{
  overflow-x: scroll;
}
.x-scroll::before {
  content: "";
  width: 2rem;
}
/* width */
.x-scroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.x-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px black; 
  border-radius: 10px;
}
 
/* Handle */
.x-scroll::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 10px;
}

/* Handle on hover */
.x-scroll::-webkit-scrollbar-thumb:hover {
  background: black; 
}

.break-word{
  overflow-wrap: break-word;
}

.input-app:focus{
  outline: none;
}

.pin:before{
  content: '';
  display: block;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.pin{
  content: '';
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  background-color: rgba(0,0,0,0.3);
  border-radius: 50%;
}

.pin-edit:before{
  mask:url(images/pencil-edit-button.svg);
}

.pin-close:before{
  mask:url(images/delete.svg);
}
.pin-check:before{
  mask:url(images/check.svg);
}

.select-app{
    background: white;
    border: none;
    color: var(--secondary);
}
.select-app:focus{
  outline: none;
}

.datepicker-app{
  color: var(--secondary);
  background: var(--light);
  font-weight: inherit;
  border:none;
  outline: none;
}
.datepicker-app:hover{
  outline: none !important;
  background: var(--light);
}

.bg-first-color{
  background-color: var(--first-color);
}
.bg-second-color{
  background-color: var(--second-color);
}
.bg-third-color{
  background-color: var(--third-color);
}


.gradient {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #EEEEEE 8%, #DEDEDE 38%, #EEEEEE 54%);
    background-size: 500px 640px;
    
    position: relative;
    
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.profile-letter{
    width: 3rem;
    height: 3rem;
    background: blue;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    text-transform: uppercase;
}
.profile-pic{
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.center-button{
  background-color: var(--first-color);
  width: 3.2rem;
  height: 3.2rem;
  position: absolute;
  top: -75%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 12px 12px -7px rgba(0,0,0,0.53);
}
.center-button > img{
  width: 1.9rem;
  filter: invert(1);
}

.navbar-icon{
  width: 1.8rem;
}
/*.modal.fade .modal-dialog {
  transform: translate(0,50px);
}*/

.square {
  position: relative;
  width: 33%;
  overflow: hidden;
  /*border-radius: 10px;*/
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;

}

input[type=checkbox]:checked + label {
  border: 4px solid var(--first-color);
  border-radius: 10px;
} 

input[type=checkbox]:checked ~ span {
  display: block;
  top: 100%;
  left: 100%;
  transform: translate(-100%, -100%) scale(0.76);
  border: 3px solid #fff;
  background-color: var(--first-color);
}
input[type=checkbox] ~ span {
  display: none;
}

.modal.fade .modal-dialog.custom {
    transform: translate(0,100px);
}
.modal.show .modal-dialog.custom {
    -webkit-transform: none;
    transform: none;
}

/*image cutter styles*/
.ic-scroll{
  overflow: scroll;
  background: #000;
  position: relative;
  width:100%;/*+5px for scrollbar*/
  max-width: 405px;
  height:405px;/*+5px for scrollbar*/
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}
.ic-scroll > canvas{
  position: absolute;
  top:0;
  left:0;
}
.ic-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.ic-scroll::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.ic-scroll::-webkit-scrollbar-thumb {
  background: rgba(255,255,255,0.75);
  border: 0px none #ffffff;
  border-radius: 16px;
}
.ic-scroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.25);
  border: 0px none #ffffff;
  border-radius: 0px;
}

.ic-scroll::-webkit-scrollbar-corner {
  background: transparent;
}
.ic-button{
  width: 2rem;
  height: 2rem;
  border: 1px solid rgba(255,255,255,0.75);
  border-radius:50%;
  background-color: rgba(0,0,0,.45);
  display: flex;
  justify-content:center;
  align-items:center;
  color:#fff;
  cursor:pointer;
  font-size: 1.3rem;
  margin: 0 .25rem;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; 
}
.ic-buttons{
  position:sticky;
  top:5%;
  left:5%;
  display:flex;
}
.ic-background{
  z-index: 10000000000;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #000000c4;
}
/*image cutter styles*/